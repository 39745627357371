import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  styled,
  useTheme
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';

import Page from '../components/Page';
import {
  getAllTickets,
  getClosedTickets,
  getMyTickets,
  getUnassignedTickets
} from '../services/TicketServices';
import { Tickets } from '../sections/ticket';
import Iconify from '../components/Iconify';
import { getUserData } from '../storage/userSessionStorage';
import { getUsers } from '../services/UserServices';
import { useToasts } from '../hooks/useToasts';

const StyledTabs = styled((props) => <Tabs {...props} />)(({ theme }) => ({
  color: theme.palette.primary.main,
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main
  },
  '& .MuiTab-textColorPrimary': {
    color: theme.palette.primary.main
  },
  '& .MuiTab-textColorPrimary.Mui-selected': {
    color: theme.palette.primary.main
  }
}));

const ticketsGridStyle = {
  overflowY: 'auto',
  '::-webkit-scrollbar': { width: 0 }
};

function TicketPage() {
  const [ticketType, setTicketType] = useState(0);
  const [search, setSearch] = useState('');
  const [socket, setSocket] = useState(null);

  const [unassignedTickets, setUnassignedTickets] = useState([]);
  const [myTickets, setMyTickets] = useState([]);
  const [closedTickets, setClosedTickets] = useState([]);
  const [allTickets, setAllTickets] = useState([]);

  const [unassignedTicketsCount, setUnassignedTicketsCount] = useState(0);
  const [myTicketsCount, setMyTicketsCount] = useState(0);
  const [closedTicketsCount, setClosedTicketsCount] = useState(0);
  const [allTicketsCount, setAllTicketsCount] = useState(0);

  const [unassignedTicketsPage, setUnassignedTicketsPage] = useState(1);
  const [myTicketsPage, setMyTicketsPage] = useState(1);
  const [closedTicketsPage, setClosedTicketsPage] = useState(1);
  const [allTicketsPage, setAllTicketsPage] = useState(1);

  const [loadingUnassignedTickets, setLoadingUnassignedTickets] = useState(true);
  const [loadingMyTickets, setLoadingMyTickets] = useState(true);
  const [loadingClosed, setLoadingClosed] = useState(true);
  const [loadingAll, setLoadingAll] = useState(true);

  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedTicket, setSelectedTicket] = useState('');

  const userData = getUserData();

  const theme = useTheme();
  const toast = useToasts();

  const isAdmin = userData?.user?.is_admin;

  const unassignedTicketsPageRef = useRef();
  const myTicketsPageRef = useRef();
  const closedTicketsPageRef = useRef();
  const allTicketsPageRef = useRef();
  const searchRef = useRef();
  const ticketTypeRef = useRef();

  unassignedTicketsPageRef.current = unassignedTicketsPage;
  myTicketsPageRef.current = myTicketsPage;
  closedTicketsPageRef.current = closedTicketsPage;
  allTicketsPageRef.current = allTicketsPage;
  searchRef.current = search;
  ticketTypeRef.current = ticketType;

  const loadAll = () => {
    setLoadingAll(true);
    getAllTickets(allTicketsPageRef.current, searchRef.current)
      .then((x) => {
        setAllTickets((prev) => [...x.data.results]);
        setAllTicketsCount(x.data.count);
        setLoadingAll(false);
      })
      .catch(() => {
        setLoadingClosed(false);
      });
  };

  const loadUnassignedTickets = () => {
    setLoadingUnassignedTickets(true);
    getUnassignedTickets(unassignedTicketsPageRef.current, searchRef.current)
      .then((x) => {
        setUnassignedTickets((prev) => [...x.data.results]);
        setUnassignedTicketsCount(x.data.count);
        setLoadingUnassignedTickets(false);
      })
      .catch(() => {
        setLoadingUnassignedTickets(false);
      });
  };

  const loadMyTickets = () => {
    setLoadingMyTickets(true);
    getMyTickets(myTicketsPageRef.current, searchRef.current)
      .then((x) => {
        setMyTickets((prev) => [...x.data.results]);
        setMyTicketsCount(x.data.count);
        setLoadingMyTickets(false);
      })
      .catch(() => {
        setLoadingMyTickets(false);
      });
  };

  const loadClosed = () => {
    setLoadingClosed(true);
    getClosedTickets(closedTicketsPageRef.current, searchRef.current)
      .then((x) => {
        setClosedTickets((prev) => [...x.data.results]);
        setClosedTicketsCount(x.data.count);
        setLoadingClosed(false);
      })
      .catch(() => {
        setLoadingClosed(false);
      });
  };

  const reload = () => {
    if (unassignedTicketsPageRef.current > 1) setUnassignedTicketsPage(1);
    else loadUnassignedTickets();

    if (myTicketsPageRef.current > 1) setMyTicketsPage(1);
    else loadMyTickets();

    if (closedTicketsPageRef.current > 1) setClosedTicketsPage(1);
    else loadClosed();

    if (isAdmin) {
      if (allTicketsPageRef.current > 1) setAllTicketsPage(1);
      else loadAll();
    }
  };

  const onConnect = () => {
    setIsConnected(true);
  };

  const onDisconnect = () => {
    setIsConnected(false);
  };

  const closedTicketListener = () => {
    reload();
  };

  const assignedTicketListener = (ticket) => {
    if (ticket) {
      toast.info('Se le asignó un nuevo ticket');
      if (myTicketsPageRef.current === 1) {
        loadMyTickets();
      }
    } else {
      setLoading(false);
      toast.info('Se asignó el ticket correctamente');
      console.log(ticketTypeRef.current);
      if (ticketTypeRef.current === 0)
        if (myTicketsPageRef.current === 1) loadMyTickets();
        else setMyTicketsPage(1);
      else if (ticketTypeRef.current === 1)
        if (unassignedTicketsPageRef.current === 1) loadUnassignedTickets();
        else setUnassignedTicketsPage(1);
      else if (ticketTypeRef.current === 2)
        if (closedTicketsPage.current === 1) loadClosed();
        else setClosedTicketsPage(1);
      else if (ticketTypeRef.current === 3)
        if (allTicketsPage.current === 1) loadAll();
        else setAllTicketsPage(1);
    }
  };

  const newTicketListener = () => {
    toast.info('Se creó un nuevo ticket');
    if (unassignedTicketsPageRef.current === 1) {
      loadUnassignedTickets();
    }
  };

  useEffect(() => {
    getUsers()
      .then((x) => {
        setUsers(x.data?.results);
        setLoading(false);
      })
      .catch(() => setLoading(false));

    if (userData.chatToken) {
      const newSocket = io(process.env.REACT_APP_CHAT_URL, {
        transports: ['websocket'],
        query: { token: userData.chatToken }
      });
      setSocket(newSocket);
      return () => newSocket.close();
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('connected', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on('closed_ticket', closedTicketListener);
      socket.on('assigned_ticket', assignedTicketListener);
      socket.on('new_ticket', newTicketListener);
      return () => {
        socket.off('closed_ticket', closedTicketListener);
        socket.off('assigned_ticket', assignedTicketListener);
        socket.off('new_ticket', newTicketListener);
        socket.off('connected', onConnect);
        socket.off('disconnect', onDisconnect);
      };
    }
  }, [socket]);

  useEffect(() => {
    const getData = setTimeout(() => {
      loadUnassignedTickets();
    }, 500);
    return () => clearTimeout(getData);
  }, [unassignedTicketsPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      loadMyTickets();
    }, 500);
    return () => clearTimeout(getData);
  }, [myTicketsPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      loadClosed();
    }, 500);
    return () => clearTimeout(getData);
  }, [closedTicketsPage]);

  useEffect(() => {
    if (isAdmin) {
      const getData = setTimeout(() => {
        loadAll();
      }, 500);
      return () => clearTimeout(getData);
    }
  }, [allTicketsPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      reload();
    }, 500);
    return () => clearTimeout(getData);
  }, [search]);

  const handleChangeType = (e, value) => {
    setTicketType(value);
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleCloseTicket = (ticketId) => {
    setLoadingUnassignedTickets(true);
    setLoadingAll(true);
    setLoadingClosed(true);
    setLoadingMyTickets(true);
    socket.emit('close_ticket', ticketId);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeUser = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleAssign = (id) => {
    setSelectedTicket(id);
    setOpenDialog(true);
  };

  const handleConfirmAssign = () => {
    setLoading(true);
    setOpenDialog(false);
    socket.emit('assign_ticket', {
      ticketId: selectedTicket,
      agent: selectedUser
    });
  };

  const getTicketsByType = () => {
    return ticketType === 0
      ? myTickets
      : ticketType === 1
      ? unassignedTickets
      : ticketType === 2
      ? closedTickets
      : allTickets;
  };

  const renderPagination = () => {
    return (
      <Stack alignItems="center">
        {ticketType === 0 ? (
          <Pagination
            count={Math.floor(myTicketsCount / 10) + 1}
            page={myTicketsPage}
            onChange={(e, p) => setMyTicketsPage(p)}
            size="small"
          />
        ) : ticketType === 1 ? (
          <Pagination
            count={Math.floor(unassignedTicketsCount / 10) + 1}
            page={unassignedTicketsPage}
            onChange={(e, p) => setUnassignedTicketsPage(p)}
            size="small"
          />
        ) : ticketType === 2 ? (
          <Pagination
            count={Math.floor(closedTicketsCount / 10) + 1}
            page={closedTicketsPage}
            onChange={(e, p) => setClosedTicketsPage(p)}
            size="small"
          />
        ) : (
          <Pagination
            count={Math.floor(allTicketsCount / 10) + 1}
            page={allTicketsPage}
            onChange={(e, p) => setAllTicketsPage(p)}
            size="small"
          />
        )}
      </Stack>
    );
  };

  return (
    <Page title="Chat">
      <Container maxWidth="lg">
        {!isConnected || loading ? (
          <Grid container spacing={4} direction="column" height="87vh">
            <Backdrop
              sx={{
                color: theme.palette.primary.main,
                zIndex: (theme) => theme.zIndex.drawer + 1
              }}
              open={!isConnected || loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Grid>
        ) : (
          <Grid container spacing={4} direction="column" height="87vh" alignItems="stretch">
            <Grid item container>
              <Grid item xs>
                <StyledTabs
                  value={ticketType}
                  onChange={handleChangeType}
                  textColor="primary"
                  indicatorColor="primary"
                >
                  <Tab value={0} label="Mis Tickets" />
                  <Tab value={1} label="Sin Asignar" />
                  <Tab value={2} label="Cerrados" />
                  {isAdmin && <Tab value={3} label="Todos" />}
                </StyledTabs>
              </Grid>
              <Grid item>
                <TextField
                  variant="standard"
                  value={search}
                  onChange={handleChangeSearch}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: 'text.disabled', width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs sx={ticketsGridStyle}>
              <Tickets
                tickets={getTicketsByType()}
                onClose={handleCloseTicket}
                onAssign={handleAssign}
                showAssign={ticketType !== 2}
                showClose={ticketType !== 2}
                showState={ticketType === 3}
                loading={
                  ticketType === 0
                    ? loadingMyTickets
                    : ticketType === 1
                    ? loadingUnassignedTickets
                    : ticketType === 2
                    ? loadingClosed
                    : loadingAll
                }
              />
            </Grid>
            <Grid item>{renderPagination()}</Grid>
          </Grid>
        )}
      </Container>
      <Dialog onClose={handleCloseDialog} open={openDialog} fullWidth>
        <DialogTitle>Asignar ticket</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id="select-label">Seleccione el usuario</InputLabel>
            <Select
              size="small"
              value={selectedUser}
              onChange={handleChangeUser}
              fullWidth
              labelId="select-label"
              label="Seleccione el usuario"
            >
              {users.map((u) => (
                <MenuItem value={u.id} key={u.id}>
                  {u.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmAssign} autoFocus>
            Asignar
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default TicketPage;
