import {
  Card,
  Grid,
  InputBase,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  useTheme
} from '@mui/material';
import Iconify from '../../components/Iconify';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Messages } from '.';

// import { AudioMessage, PhotoMessage, FileMessage } from 'react-chat-elements';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const zeroPad = (num, places) => String(num).padStart(places, '0');

function Chat({ ticket, onBack, onClose, onAssign, onAddMessage, showClose, showAssign }) {
  const theme = useTheme();
  const [newMessage, setNewMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();

  const inputFileReference = useRef(null);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const buildMessages = () => {
    let dailyMessages = [];
    let result = [];

    ticket?.messages.forEach((x) => {
      if (
        dailyMessages.length > 0 &&
        !moment(dailyMessages[dailyMessages.length - 1].dateTime).isSame(moment(x.dateTime), 'day')
      ) {
        result.push({
          date: dailyMessages[0].dateTime,
          messages: buildDailyMessages(dailyMessages)
        });
        dailyMessages = [x];
      } else {
        dailyMessages.push(x);
      }
    });
    if (dailyMessages.length > 0) {
      result.push({ date: dailyMessages[0].dateTime, messages: buildDailyMessages(dailyMessages) });
    }

    return result;
  };

  const buildDailyMessages = (messages) => {
    let userMessages = [];
    let result = [];
    messages.forEach((x) => {
      if (userMessages.length > 0 && userMessages[userMessages.length - 1].origin !== x.origin) {
        result.push({ origin: userMessages[0].origin, messages: buildUserMessage(userMessages) });
        userMessages = [x];
      } else {
        userMessages.push(x);
      }
    });
    if (userMessages.length > 0) {
      result.push({ origin: userMessages[0].origin, messages: buildUserMessage(userMessages) });
    }

    return result;
  };

  const buildUserMessage = (messages) => {
    let timeMessages = [];
    let result = [];

    messages.forEach((x) => {
      if (
        timeMessages.length > 0 &&
        !moment(timeMessages[0].dateTime).isSame(moment(x.dateTime), 'minute')
      ) {
        result.push({
          date: timeMessages[0].dateTime,
          messages: timeMessages.map((x) => ({ text: x.text, type: x.type }))
        });
        timeMessages = [x];
      } else {
        timeMessages.push(x);
      }
    });
    if (timeMessages.length > 0) {
      result.push({
        date: timeMessages[0].dateTime,
        messages: timeMessages.map((x) => ({ text: x.text, type: x.type }))
      });
    }

    return result;
  };

  const messagesGridStyle = {
    overflowY: 'auto',
    '::-webkit-scrollbar': { width: 0 }
  };

  const cardStyle = {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'end',
    width: '100%'
  };

  const sendStyle = {
    borderRadius: '5px',
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    ':hover': {
      backgroundColor: theme.palette.grey[200],
      cursor: 'pointer'
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    onClose();
  };

  const handleBack = () => {
    onBack();
  };

  const handleSendMessage = () => {
    onAddMessage(newMessage, selectedFile);
    setNewMessage('');
    setSelectedFile(undefined);
  };

  const handleNewMessageChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleClickAttachment = () => {
    inputFileReference.current.click();
  };

  const handlerFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  return (
    <>
      <Grid container direction="column" sx={{ height: '100%' }}>
        <Grid item>
          <Card sx={{ ...cardStyle, marginBottom: theme.spacing(2) }}>
            <IconButton onClick={handleBack}>{getIcon('eva:arrow-back-outline')}</IconButton>
            <Typography
              variant="h6"
              component="span"
              sx={{ width: '100%', textAlign: 'center', alignSelf: 'center' }}
            >
              Ticket #{ticket ? zeroPad(ticket.id, 5) : ''}
            </Typography>
            {(showClose || showAssign) && (
              <IconButton sx={{ marginLeft: 'auto' }} onClick={handleOpenMenu}>
                {getIcon('eva:more-vertical-outline')}
              </IconButton>
            )}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              {showClose && <MenuItem onClick={handleClose}>Cerrar Ticket</MenuItem>}
              {showAssign && <MenuItem onClick={onAssign}>Asignar Ticket</MenuItem>}
            </Menu>
          </Card>
        </Grid>
        <Grid item xs sx={messagesGridStyle}>
          <Messages messages={buildMessages()} username={ticket?.chatUser?.name} />
        </Grid>
        <Grid item>
          <Card sx={cardStyle}>
            <Grid container direction="column">
              {selectedFile && (
                <>
                  <Grid item>
                    <IconButton
                      aria-label="close"
                      sx={{ float: 'right' }}
                      onClick={() => setSelectedFile(undefined)}
                    >
                      {getIcon('eva:close-fill')}
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {selectedFile.type.startsWith('image') ? (
                      <Grid item>
                        <img
                          src={preview}
                          alt="preview"
                          style={{ maxWidth: '100%', maxHeight: '60vh' }}
                        />
                      </Grid>
                    ) : (
                      <Grid item>
                        <Iconify
                          icon="eva:file-outline"
                          width={100}
                          height={100}
                          sx={{ float: 'center' }}
                        />
                      </Grid>
                    )}
                    <p>{selectedFile.name}</p>
                  </Grid>
                </>
              )}
              <Grid item sx={{ display: 'flex' }}>
                <IconButton
                  size="large"
                  aria-label="Enviar"
                  sx={sendStyle}
                  onClick={handleClickAttachment}
                >
                  {getIcon('eva:attach-2-fill')}
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Escriba su mensaje"
                  multiline
                  fullWidth
                  value={newMessage}
                  onChange={handleNewMessageChange}
                />
                <IconButton
                  size="large"
                  aria-label="Enviar"
                  sx={sendStyle}
                  onClick={handleSendMessage}
                >
                  {getIcon('eva:paper-plane-outline')}
                </IconButton>
                <input
                  type="file"
                  name="file"
                  onChange={handlerFileChange}
                  ref={inputFileReference}
                  hidden
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Chat;
