import {
  Container,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button
} from '@mui/material';
import Page from '../components/Page';
import { useEffect, useRef, useState } from 'react';
import { Chat } from '../sections/chat';
import { getUserData } from '../storage/userSessionStorage';
import { useNavigate, useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useTheme } from '@emotion/react';
// import { getTicketData } from 'src/storage/ticketLocalStorage';
import { uploadFile } from 'src/services/FileService';
import { getUsers } from 'src/services/UserServices';

function ChatPage() {
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [socket, setSocket] = useState(null);
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const lastRead = useRef(-1);
  const userData = getUserData();
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  // useEffect(() => {
  //   if (ticket) {
  //     const ticketData = getTicketData(ticketId);
  //     const l = ticketData
  //       ? ticketData.lastRead
  //       : ticket && ticket.messages.length > 0
  //       ? ticket?.messages[0].id
  //       : -1;

  //     lastRead.current = l;

  //     return () => {
  //       console.log(lastRead.current);
  //     };
  //   }
  // }, [ticket]);

  useEffect(() => {
    getUsers()
      .then((x) => {
        setUsers(x.data?.results.filter((x) => x.id !== userData?.user?.id));
        setLoadingUsers(false);
      })
      .catch(() => setLoadingUsers(false));

    if (userData.chatToken) {
      const newSocket = io(process.env.REACT_APP_CHAT_URL, {
        transports: ['websocket'],
        query: { token: userData.chatToken }
      });
      setSocket(newSocket);
      return () => newSocket.close();
    }
  }, []);

  useEffect(() => {
    const messageListener = (m) => {
      setTicket((prev) => {
        const newMessages = [...prev.messages, m];
        lastRead.current = m.id;
        return { ...prev, messages: newMessages };
      });
    };

    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function ticketListener(m) {
      setTicket(m);
      setLoading(false);
    }

    function closedTicketListener() {
      setLoading(false);
      navigate(-1);
    }

    function assignedTicketListener() {
      setLoading(false);
      navigate(-1);
    }

    if (socket) {
      socket.on('connected', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.on('receive_message', messageListener);
      socket.on('receive_ticket', ticketListener);
      socket.on('closed_ticket', closedTicketListener);
      socket.on('assigned_ticket', assignedTicketListener);
      return () => {
        socket.off('receive_ticket', messageListener);
        socket.off('receive_message', ticketListener);
        socket.off('receive_message', ticketListener);
        socket.off('closed_ticket', closedTicketListener);
        socket.off('assigned_ticket', assignedTicketListener);
        socket.off('connected', onConnect);
        socket.off('disconnect', onDisconnect);
      };
    }
  }, [socket]);

  useEffect(() => {
    if (isConnected) socket.emit('get_ticket', ticketId);
  }, [isConnected]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleAddMessage = async (text, file) => {
    if (file) {
      const filename = await uploadFile(file);
      const messageType = file.type.startsWith('image')
        ? 'Image'
        : file.type.startsWith('audio')
        ? 'Audio'
        : file.type.startsWith('video')
        ? 'Video'
        : 'File';
      socket.emit('send_message', {
        type: messageType,
        text: filename
      });
    }
    if (text) {
      socket.emit('send_message', {
        type: 'Text',
        text: text
      });
    }
  };

  const handleClose = () => {
    setLoading(true);
    socket.emit('close_ticket');
  };

  const confirmAssign = () => {
    setLoading(true);
    setOpenDialog(false);
    socket.emit('assign_ticket', {
      ticketId: ticket.id,
      agent: selectedUser
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeUser = (event) => {
    setSelectedUser(event.target.value);
  };

  return (
    <Page title="Chat">
      <Container maxWidth="lg">
        <Grid container spacing={4} direction="column" height="87vh">
          <Grid item xs>
            {loading && isConnected ? (
              <Backdrop
                sx={{
                  color: theme.palette.primary.main,
                  zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <Chat
                ticket={ticket}
                onBack={handleBack}
                onClose={handleClose}
                onAssign={() => setOpenDialog(true)}
                onAddMessage={handleAddMessage}
                showAssign={ticket?.state !== 'closed'}
                showClose={ticket?.state !== 'closed'}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      <Dialog onClose={handleCloseDialog} open={openDialog} fullWidth>
        <DialogTitle>Asignar ticket</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id="select-label">Seleccione el usuario</InputLabel>
            <Select
              size="small"
              value={selectedUser}
              onChange={handleChangeUser}
              fullWidth
              labelId="select-label"
              label="Seleccione el usuario"
            >
              {users.map((u) => (
                <MenuItem value={u.id}>{u.username}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmAssign} autoFocus>
            Asignar
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default ChatPage;
