import axios from 'axios';
import { getUserData } from '../storage/userSessionStorage';

async function getUsers() {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `jwt ${userData.token}`
    }
  });

  return await axiosClient.get(`/api/users`);
}

export { getUsers };
