const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: 'eva:pie-chart-2-fill'
  },
  {
    title: 'Tickets',
    icon: 'eva:file-text-fill',
    path: '/ticket/app'
  },
  {
    title: 'Contacts',
    icon: 'eva:people-fill',
    path: '/contact/app'
  }
];

export default sidebarConfig;
