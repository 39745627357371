import {
  Card,
  Grid,
  IconButton,
  styled,
  useTheme,
  Typography,
  Menu,
  MenuItem,
  Box,
  CircularProgress
} from '@mui/material';
import Iconify from '../../components/Iconify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const zeroPad = (num, places) => String(num).padStart(places, '0');

const StyledCard = styled((props) => <Card {...props} />)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  marginBottom: theme.spacing(2),
  ':hover': {
    backgroundColor: theme.palette.grey[200],
    cursor: 'pointer'
  }
}));

const states = {
  assigned: 'Asignado',
  closed: 'Cerrado',
  unassigned: 'Sin Asignar'
};

function Tickets({ tickets, onClose, onAssign, showClose, showAssign, showState, loading }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleOpenMenu = (event, ticketId) => {
    setAnchorEl(event.currentTarget);
    setSelectedTicket(ticketId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose(selectedTicket);
  };

  const handleAssign = () => {
    setAnchorEl(null);
    onAssign(selectedTicket);
  };

  const handleClick = (id) => {
    navigate(`/chat/${id}`);
  };

  return loading ? (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      {tickets.map((x) => (
        <StyledCard key={x.id}>
          <Grid container alignItems="center">
            <Grid item xs onClick={() => handleClick(x.id)}>
              <Typography variant="h6" component="span">
                {x.chat_user.name}
                {'  '}
              </Typography>
              <ul>
                <li style={{ display: 'inline-block', marginRight: theme.spacing(5) }}>
                  <Typography variant="body1">
                    Última Actualización:{' '}
                    {x.last_update ? moment(x.last_update).format('MM/DD/yyyy HH:mm') : ''}
                  </Typography>
                </li>
                <li style={{ display: 'inline-block', marginRight: theme.spacing(5) }}>
                  <Typography variant="body1">Comentarios: {x.messages}</Typography>
                </li>
                <li style={{ display: 'inline-block', marginRight: theme.spacing(5) }}>
                  <Typography variant="body1">Ticket #: {zeroPad(x.id, 5)}</Typography>
                </li>
                {showState && (
                  <li style={{ display: 'inline-block', marginRight: theme.spacing(5) }}>
                    <Typography variant="body1">Estado: {states[x.state]}</Typography>
                  </li>
                )}
              </ul>
            </Grid>
            {(showClose || showAssign) && (
              <Grid item>
                <IconButton onClick={(e) => handleOpenMenu(e, x.id)}>
                  {getIcon('eva:more-vertical-outline')}
                </IconButton>
              </Grid>
            )}
          </Grid>
        </StyledCard>
      ))}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {showClose && <MenuItem onClick={handleClose}>Cerrar Ticket</MenuItem>}
        {showAssign && <MenuItem onClick={handleAssign}>Reasignar Ticket</MenuItem>}
      </Menu>
    </>
  );
}

export default Tickets;
