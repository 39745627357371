import axios from 'axios';
import { getUserData } from '../storage/userSessionStorage';

async function getUnassignedTickets(page, search) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `jwt ${userData.token}`
    }
  });

  return await axiosClient.get(`/api/ticket/?state=unassigned&page=${page}&search=${search}`);
}

async function getMyTickets(page, search) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `jwt ${userData.token}`
    }
  });

  return await axiosClient.get(
    `/api/ticket/?agent=${userData.user.id}&state=assigned&page=${page}&search=${search}`
  );
}

async function getClosedTickets(page, search) {
  const userData = getUserData();
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `jwt ${userData.token}`
    }
  });

  return await axiosClient.get(
    `/api/ticket/?agent=${userData.user.id}&state=closed&page=${page}&search=${search}`
  );
}

async function getAllTickets(page, search) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `jwt ${userData.token}`
    }
  });

  return await axiosClient.get(`/api/ticket/?page=${page}&search=${search}`);
}

async function getByState() {
  const userData = getUserData();
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_CHAT_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `jwt ${userData.token}`
    }
  });

  return await axiosClient.get(`/ticket/bystateanduser?user=${userData.user.id}`);
}

async function getByDate(type) {
  const userData = getUserData();
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_CHAT_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `jwt ${userData.token}`
    }
  });

  return await axiosClient.get(`/ticket/byDate?type=${type}`);
}

export {
  getUnassignedTickets,
  getMyTickets,
  getClosedTickets,
  getAllTickets,
  getByState,
  getByDate
};
