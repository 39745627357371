import {
  Container,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
  Button
} from '@mui/material';
import Page from '../components/Page';
import { useEffect, useRef, useState } from 'react';
import { getUserData } from '../storage/userSessionStorage';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { getContacts } from 'src/services/ContactServices';
import { Contacts } from 'src/sections/contact';
// import { getTicketData } from 'src/storage/ticketLocalStorage';

function ContactPage() {
  const [contacts, setContacts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const theme = useTheme();

  useEffect(() => {
    getContacts(page, 10)
      .then((x) => {
        setContacts(x.data ? x.data.result : []);
        setTotal(x.data ? x.data.count : 0);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [page]);

  return (
    <Page title="Chat">
      <Container maxWidth="lg">
        <Grid container spacing={4} direction="column" height="87vh">
          <Grid item xs>
            {contacts === null ? (
              <Backdrop
                sx={{
                  color: theme.palette.primary.main,
                  zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <Contacts
                contacts={contacts}
                loading={loading}
                total={total}
                page={page}
                onChangePage={(p) => setPage(p)}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default ContactPage;
