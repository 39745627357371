// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Card,
  styled,
  CardHeader,
  CardContent,
  Button,
  useTheme
} from '@mui/material';
// components
import Page from '../components/Page';

import { Chart as ChartJS, registerables } from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import { getByDate, getByState } from 'src/services/TicketServices';

ChartJS.register(...registerables);
// ----------------------------------------------------------------------

const TicketBox = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  padding: theme.spacing(2)
}));

const OutlinedButton = styled((props) => <Button {...props} variant="outlined" />)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  borderRadius: 0,
  ':hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.light
  }
}));

const ContainedButton = styled((props) => <Button {...props} variant="contained" />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.light,
    borderRadius: 0,
    ':hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.light
    }
  })
);

const options = {
  indexAxis: 'x',
  maintainAspectRatio: false,
  elements: {
    bar: {
      borderWidth: 0
    },
    line: {
      zIndex: 10000
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom'
    },
    title: {
      display: false
    }
  }
};

export default function DashboardApp() {
  const theme = useTheme();

  // const data123 = {
  //   labels,
  //   datasets: [
  //     {
  //       type: 'line',
  //       label: 'Tickets Completados',
  //       data: [2, 3, 2, 6, 8, 1, 2, 3, 5, 3, 6, 9],
  //       borderColor: theme.palette.secondary.light,
  //       backgroundColor: theme.palette.secondary.light
  //     },
  //     {
  //       type: 'bar',
  //       label: 'Tickets Nuevos',
  //       data: [1, 2, 5, 9, 5, 3, 2, 3, 2, 8, 7, 9],
  //       borderColor: theme.palette.primary.main,
  //       backgroundColor: theme.palette.primary.main
  //     }
  //   ]
  // };

  const [ticketsByState, setTicketByState] = useState([
    { state: 'unassigned', count: 0 },
    { state: 'assigned', count: 0 },
    { state: 'closed', count: 0 }
  ]);

  const [currentTab, setCurrentTab] = useState('today');
  const [data, setData] = useState(null);

  useEffect(() => {
    getByState().then((x) => {
      setTicketByState(x.data);
    });
  }, []);

  useEffect(() => {
    getByDate(currentTab).then((x) => {
      switch (currentTab) {
        case 'today':
        case 'yesterday':
          var r = [];
          for (let i = 0; i < x.data.length - 1; i = i + 2) {
            r.push(x.data[i] + x.data[i + 1]);
          }
          setData({
            labels: [
              '1 AM',
              '3 AM',
              '5 AM',
              '7 AM',
              '9 AM',
              '11 AM',
              '1 PM',
              '3 PM',
              '5 PM',
              '7 PM',
              '9 PM',
              '11 PM'
            ],
            datasets: [
              {
                type: 'line',
                label: 'Tickets Nuevos',
                data: r,
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.light
              }
            ]
          });
          break;
        case 'week':
          setData({
            labels: ['Domingo', 'Lunes', 'Marte', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
            datasets: [
              {
                type: 'line',
                label: 'Tickets Nuevos',
                data: x.data,
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.light
              }
            ]
          });
          break;
        default:
          setData({
            labels: x.data.map((x, i) => i + 1),
            datasets: [
              {
                type: 'line',
                label: 'Tickets Nuevos',
                data: x.data,
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.light
              }
            ]
          });
          break;
      }
    });
  }, [currentTab]);

  return (
    <Page title="Dashboard">
      <Container maxWidth="lg">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Card>
              <CardHeader title="Estado de los Tickets" />
              <CardContent>
                <Grid container p={1} spacing={5}>
                  <Grid item xs={12} md={4}>
                    <TicketBox>
                      <Typography variant="h6">Mis Tickets</Typography>
                      <Typography variant="h3" sx={{ color: 'primary.main' }}>
                        {ticketsByState.find((x) => x.state === 'assigned').count}
                      </Typography>
                    </TicketBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TicketBox>
                      <Typography variant="h6">Sin Asignar</Typography>
                      <Typography variant="h3" sx={{ color: 'primary.main' }}>
                        {ticketsByState.find((x) => x.state === 'unassigned').count}
                      </Typography>
                    </TicketBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TicketBox>
                      <Typography variant="h6">Completados</Typography>
                      <Typography variant="h3" sx={{ color: 'primary.main' }}>
                        {ticketsByState.find((x) => x.state === 'closed').count}
                      </Typography>
                    </TicketBox>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <CardHeader title="Tickets" />
              <CardContent>
                <Grid container direction="row" spacing={2} justifyContent="center">
                  <Grid item>
                    {currentTab === 'today' ? (
                      <ContainedButton onClick={() => setCurrentTab('today')}>Hoy</ContainedButton>
                    ) : (
                      <OutlinedButton onClick={() => setCurrentTab('today')}>Hoy</OutlinedButton>
                    )}
                  </Grid>
                  <Grid item>
                    {currentTab === 'yesterday' ? (
                      <ContainedButton onClick={() => setCurrentTab('yesterday')}>
                        Ayer
                      </ContainedButton>
                    ) : (
                      <OutlinedButton onClick={() => setCurrentTab('yesterday')}>
                        Ayer
                      </OutlinedButton>
                    )}
                  </Grid>
                  <Grid item>
                    {currentTab === 'week' ? (
                      <ContainedButton onClick={() => setCurrentTab('week')}>
                        Esta Semana
                      </ContainedButton>
                    ) : (
                      <OutlinedButton onClick={() => setCurrentTab('week')}>
                        Esta Semana
                      </OutlinedButton>
                    )}
                  </Grid>
                  <Grid item>
                    <Grid item>
                      {currentTab === 'month' ? (
                        <ContainedButton onClick={() => setCurrentTab('month')}>
                          Este Mes
                        </ContainedButton>
                      ) : (
                        <OutlinedButton onClick={() => setCurrentTab('month')}>
                          Este Mes
                        </OutlinedButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <div style={{ height: '350px' }}>
                  {data && <Bar options={options} data={data}></Bar>}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
