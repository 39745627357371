import axios from 'axios';

async function uploadFile(file) {
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_CHAT_URL,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  const formData = new FormData();
  formData.append('file', file);

  const result = await axiosClient.post('/file/upload', formData);
  const filename = result.data;
  console.log(filename);
  return filename;
}

export { uploadFile };
