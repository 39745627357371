import moment from 'moment';
import { Button, Typography, styled, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import Iconify from '../../components/Iconify';
import { downloadURI } from '../../utils/downloadFile';
import parse from 'html-react-parser';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const MessageReceived = styled('p')(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(1),
  borderRadius: Number(theme.shape.borderRadius),
  borderTopLeftRadius: 0,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.main,
  display: 'inline-block'
}));

const MessageSend = styled('p')(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(1),
  borderRadius: Number(theme.shape.borderRadius),
  borderTopRightRadius: 0,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.light,
  display: 'inline-block'
}));

const MessagesBoxSend = styled('div')(({ theme }) => ({
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(1.5),
  display: 'flex'
}));

const MessagesSend = styled('div')(({ theme }) => ({
  textAlign: 'right',
  maxWidth: '80%',
  width: 'auto'
}));

const MessagesBoxReceived = styled('div')(({ theme }) => ({
  alignItems: 'flex-start',
  marginBottom: theme.spacing(1.5),
  display: 'flex'
}));

const MessagesReceived = styled('div')(({ theme }) => ({
  textAlign: 'left',
  maxWidth: '80%',
  width: 'auto'
}));

const FileMessage = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.grey[200]
  }
}));

const formatAmPm = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

function Messages({ messages, username }) {
  const matches = useMediaQuery('(min-width:1000px)');
  const endRef = useRef(null);

  useEffect(() => {
    endRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages, endRef]);

  const renderUserMessages = (messages) => {
    return messages.map((x) =>
      x.origin === 'Agente' ? (
        <MessagesBoxSend>
          <MessagesSend>{renderTimeMessages(x.messages, x.origin)}</MessagesSend>
        </MessagesBoxSend>
      ) : (
        <MessagesBoxReceived>
          <MessagesReceived>
            <Typography variant="h6">{x.origin === 'Usuario' ? username : 'Chat Bot'}</Typography>
            {renderTimeMessages(x.messages, x.origin)}
          </MessagesReceived>
        </MessagesBoxReceived>
      )
    );
  };

  const renderTimeMessages = (messages, origin) => {
    return messages.map((x) =>
      origin === 'Agente' ? (
        <>
          {renderMessage(x.messages, origin)}
          <Typography variant="caption">{formatAmPm(new Date(x.date))}</Typography>
        </>
      ) : (
        <>
          {renderMessage(x.messages, origin)}
          <Typography variant="caption">{formatAmPm(new Date(x.date))}</Typography>
        </>
      )
    );
  };

  const renderMessage = (messages, origin) => {
    return messages.map((x) => (
      <div>
        {origin === 'Agente' ? (
          <MessageSend>{renderMessageByType(x.text, x.type)}</MessageSend>
        ) : (
          <MessageReceived>{renderMessageByType(x.text, x.type)}</MessageReceived>
        )}
      </div>
    ));
  };

  const renderMessageByType = (text, type) => {
    const file = text.split('|');
    return (
      <>
        {type === 'Image' && (
          <img
            alt="message"
            src={`${process.env.REACT_APP_CHAT_URL}/file/${file[0]}`}
            style={{ width: matches ? '500px' : '' }}
          />
        )}
        {type === 'Audio' && (
          <audio controls autoPlay={false} style={{ width: matches ? '500px' : '' }}>
            <source src={`${process.env.REACT_APP_CHAT_URL}/file/${file[0]}`} />
            Your browser does not support the audio element.
          </audio>
        )}
        {type === 'Video' && (
          <video controls autoPlay={false} style={{ width: matches ? '500px' : '' }}>
            <source src={`${process.env.REACT_APP_CHAT_URL}/file/${file[0]}`} />
            Your browser does not support the video element.
          </video>
        )}
        {type === 'File' && (
          <FileMessage
            startIcon={getIcon('eva:file-outline')}
            endIcon={getIcon('eva:cloud-download-outline')}
            onClick={() =>
              downloadURI(`${process.env.REACT_APP_CHAT_URL}/file/${file[0]}`, file[1])
            }
          >
            {file[1]}
          </FileMessage>
        )}
        {type === 'Text' && parse(text)}
      </>
    );
  };

  return (
    <React.Fragment>
      {messages.map((x) => {
        return moment(x.date).isSame(moment(new Date()), 'day') ? (
          <>
            <Typography variant="caption" sx={{ textAlign: 'center' }} component="p">
              Hoy
            </Typography>
            {renderUserMessages(x.messages)}
          </>
        ) : (
          <>
            <Typography variant="caption" sx={{ textAlign: 'center' }} component="p">
              {moment(x.date).format('yyyy-MM-DD')}
            </Typography>
            {renderUserMessages(x.messages)}
          </>
        );
      })}
      <div style={{ clear: 'both' }} ref={endRef}></div>
    </React.Fragment>
  );
}

export default Messages;
