import axios from 'axios';

async function loginService(cred) {
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const result = await axiosClient.post('/api/auth/obtain_token/', cred);
  const token = result.data.token;
  return token;
}

async function loginChat(cred) {
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_CHAT_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const result = await axiosClient.post('/auth/obtain_token_agent', cred);
  const token = result.data.token;
  return token;
}

async function getUser(token) {
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `jwt ${token}`
    }
  });

  const result = await axiosClient.get('/api/get_current_user/');
  return result.data;
}

export { loginService, getUser, loginChat };
