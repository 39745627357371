import { Box, Card, CircularProgress, Grid, Pagination, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const StyledCard = styled((props) => <Card {...props} />)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  marginBottom: theme.spacing(1),
  '&.active': {
    backgroundColor: theme.palette.grey[100]
  },
  ':hover': {
    cursor: 'pointer',
    backgroundColor: `${theme.palette.grey[200]}`
  },
  '&.header': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey[200]
  }
}));

function Contacts({ contacts, loading, total, page, onChangePage }) {
  const navigate = useNavigate();

  const handleChange = (e, p) => {
    onChangePage(p);
  };

  const handleClick = (id) => {
    navigate(`/chat/${id}`);
  };

  return loading ? (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <StyledCard className="header">
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography variant="h5" component="span">
              Name
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h5" component="span">
              Email
            </Typography>
          </Grid>
        </Grid>
      </StyledCard>
      {contacts.map((x, i) => (
        <StyledCard
          key={x.id}
          className={i % 2 !== 0 ? 'active' : ''}
          onClick={() => handleClick(x.ticket?.id)}
        >
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h6" component="span">
                {x.name}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h6" component="span">
                {x.email}
              </Typography>
            </Grid>
          </Grid>
        </StyledCard>
      ))}
      <Box
        sx={{
          margin: 'auto',
          width: 'fit-content',
          alignItems: 'center'
        }}
      >
        <Pagination
          sx={{ width: '100%' }}
          page={page}
          count={Math.floor(total / 10)}
          color="primary"
          size="large"
          onChange={handleChange}
        />
      </Box>
    </>
  );
}

export default Contacts;
