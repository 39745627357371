import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

//
import DashboardApp from './pages/DashboardApp';
import NotFound from './pages/Page404';
import Ticket from './pages/TicketsPage';
import Chat from './pages/ChatPage';
import Login from './pages/Login';
import { getUserData } from './storage/userSessionStorage';
import Contact from './pages/ContactPage';

// ----------------------------------------------------------------------

function ProtectedRoute({ children }) {
  const { token } = getUserData();
  const notAllowed = !token;

  if (notAllowed) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: 'app',
          element: (
            <ProtectedRoute>
              <DashboardApp />
            </ProtectedRoute>
          )
        }
      ]
    },
    {
      path: '/ticket',
      element: <DashboardLayout />,
      children: [
        {
          path: 'app',
          element: (
            <ProtectedRoute>
              <Ticket />
            </ProtectedRoute>
          )
        }
      ]
    },
    {
      path: '/chat',
      element: <DashboardLayout />,
      children: [
        {
          path: ':ticketId',
          element: (
            <ProtectedRoute>
              <Chat />
            </ProtectedRoute>
          )
        }
      ]
    },
    {
      path: '/contact',
      element: <DashboardLayout />,
      children: [
        {
          path: ':ticketId',
          element: (
            <ProtectedRoute>
              <Contact />
            </ProtectedRoute>
          )
        }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
